import Vue from 'vue';
import VueRouter from 'vue-router';
import main from '../views/index.vue';
import home from '../views/home';
import mine from '../views/mine';
import setPwd from '../views/mine/setPwd';
import details from '../views/mine/details';

Vue.use(VueRouter);

const routes = [{
  path: '/',
  name: 'main',
  redirect: '/main/home',
  component: main,
  children: [
    {
      path: '/main/home',
      name: 'main.home',
      component: home,
      meta: {
        name: '首页',
      },
    },
    {
      path: '/main/mine',
      name: 'main.mine',
      component: mine,
      meta: {
        name: '我的',
      },
    },
    {
      path: '/main/setPwd',
      name: 'main.setPwd',
      component: setPwd,
      meta: {
        name: '设置密码',
      },
    },
    {
      path: '/main/details',
      name: 'main.details',
      component: details,
      meta: {
        name: '详情',
      },
    }
  ],
},];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;