import Vue from 'vue'
import App from './App.vue'
import router from './router'
import request from '@/assets/api/request'
import '@/assets/less/common.less'
import Vant from 'vant';
import 'vant/lib/index.css'
import VueCookies from 'vue-cookies'
import '@/assets/utils/filter'

Vue.use(VueCookies)

Vue.use(Vant);

Vue.config.productionTip = false



// 挂载request
Vue.prototype.request = request


new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
