<template>
  <div class="details">
    <div class="topBox">
      <div class="logoBox">
        <img :src="info.logoImg" style="width:37.53px" alt />
        <span style="font-size:23.184px">{{info.webLogoText}}</span>
      </div>
      <div class="iconBox">
        <div class="df_btn" @click="goHome()">首页</div>
      </div>
    </div>
    <div class="content">
      <p class="email">{{details.title}}</p>
      <img :src="details.headImgUrl" style="width:110.39px;height:110.39px;display:block;margin:10px auto;" />
      <p class="member">{{details.memberCount}} members</p>
      <p class="member">更新时间：{{details.updateTime}}</p>
      <p class="member">{{details.description}}</p>
      <div class="btn" @click="addGroup()">加入</div>
      <div class="swipeBox">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" height="80">
          <van-swipe-item v-for="item in imgList" :key="item.rotationId">
            <img :src="item.rotationImg" alt style="width:100%; height:80px" @click="goUrl(item.jumpSrc)" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <h3 style="font-size:17.6654px">相关推荐>></h3>
      <div class="recommandBox">
        <div class="box" v-for="item in recommandData" :key="item.id">
          <img :src="item.headImgUrl" alt />
          <div class="infoBox">
            <div class="info" style="font-size:15.4546px;min-height:80px;">{{item.title}}</div>
            <div class="btnBox">
              <span style="font-size:15.4546px;color:#999;">{{item.memberCount}} members</span>
              <div class="df_btn" @click="refashPage(item.id)">查看</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      showDialog: false,
      info: {},
      userInfo: {},
      imgList: [],
      details: {},
      recommandData: [],
      pageNo: 1,
      pageSize: 10,
    };
  },
  methods: {
    goUrl(url){
      window.open(url, "_blank");
    },
    addGroup() {
      this.$dialog.confirm({
        message: "确认加入吗？",
        beforeClose: async (action, done) => {
          if (action === "confirm") {
            const res = await this.request({
              url: "/api/login/getUrl",
              method: "get",
              params: {
                id: this.id,
              },
            });
            if (res.success) {
              this.$toast("添加成功");
              setTimeout(()=>{
                window.open(res.result, "_blank");
              },500)
            } else {
              this.$toast("添加失败");
            }
          }
          done();
        },
      });
    },
    openAddDialog() {
      this.showDialog = true;
    },
    goHome() {
      this.$router.push({
        path: "/main/home",
      });
    },
    refashPage(id) {
      this.id = id;
      this.initPage();
    },
    async initPage() {
      //获取详情接口
      const res2 = await this.request({
        url: "/api/local-api/bug_keyword/webGetById",
        method: "get",
        params: {
          id: this.id,
        },
      });
      this.details = res2.data;

      //获取轮播接口
      const res = await this.request({
        url: "/api/local-api/rotation/page",
        method: "get",
      });

      this.imgList = res.data.list;

      //推荐列表
      const res3 = await this.request({
        url: "/api/local-api/bug_keyword/recommend",
        method: "get",
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          key: this.key,
        },
      });
      this.recommandData = res3.data.list;
    },
  },
  async mounted() {
    this.id = this.$route.query.id;
    this.info = JSON.parse(localStorage.getItem("info")) || {};

    this.initPage();
  },
};
</script>
<style lang="less" socped>
.details {
  width: 100%;
  min-height: calc(100% - 30px);
  padding: 0 10px;
  box-sizing: border-box;
  .topBox {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .logoBox {
      flex: 1;
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        color: #fff;
        margin-left: 5px;
      }
    }
    .iconBox {
      display: flex;
      align-items: center;
      .df_btn{
        width: 66.23px;
        height: 33.11px;
        line-height: 33.11px;
        background: #284E74;
        color:#eee;
      }
    }
  }
  .content {
    margin: 0 auto;
    .email {
      font-size: 18px;
      color: #fff;
      text-align: center;
      margin: 10px 20px;
    }
    .line {
      display: flex;
      align-items: center;
      color: #fff;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #fff;
      .name {
        flex: 1;
      }
    }
    .btn {
      width: 90%;
      height: 45px;
      line-height: 45px;
      text-align: center;
      color: #fff;
      background: #284E74;
      border-radius: 8px;
      margin-top: 20px;
      margin-left: 5%;
    }
    .member {
      text-align: center;
      color: #fff;
      font-size: 13px;
      margin-top: 10px;
    }
  }
  .swipeBox {
    height: 80px;
    background: #fff;
    margin-top: 20px;
  }
  h3 {
    color: #fff;
    font-size: 16px;
    margin: 20px 0 10px;
  }
  .recommandBox {
    padding: 0 0 10px;
    .box {
      display: flex;
      margin-top: 10px;
      img {
        width: 110.39px;
        height: 110.39px;
        border-radius: 5px;
        margin-right: 10px;
      }
      .infoBox {
        flex: 1;
        .info {
          color: #fff;
          font-size: 14px;
          line-height: 1.6;
          min-height: 50px;
        }
        .btnBox {
          display: flex;
          align-items: center;
          span {
            flex: 1;
            color: #fff;
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
