<template>
  <div class="sfin">
      <router-view />
      <div v-html="info.bottomFont" class="tj"></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      info:{}
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  async mounted() {
     const loginInfo = await this.request({
      url: "/api/login/doLogin/no/pass",
      method: "get",
    });
    this.$cookies.set('user_no_pass_cookie',loginInfo.result.user_no_pass_cookie)

    //获取自定义配置信息
    const res = await this.request({
      url: "/api/local-api/rotation/getWebCustom",
      method: "get",
    });
    this.info = res.data;
    localStorage.setItem('info',JSON.stringify(this.info));
  },
};
</script>

<style lang="less">
.sfin {
  width: 100%;
  height: 100%;
  
}
.tj{
    color:#fff;
    text-align: center;
    font-size: 15.4546px;
  }
</style>
