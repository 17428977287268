<template>
  <div class="mine">
    <div class="topBox">
      <div class="logoBox">
        <img :src="info.logoImg" style="width:37.53px" alt />
        <span style="font-size:23.184px">{{info.webLogoText}}</span>
      </div>
      <div class="iconBox">
        <div class="df_btn" @click="goHome()">首页</div>
      </div>
    </div>
    <div class="content">
      <img :src="info.logoImg" style="width:103.5px;eight:103.5px;display:block;margin:20px auto;" />
      <p class="email" style="font-size:20.7px;">{{userInfo.userName?userInfo.userName:'未设置邮箱'}}</p>
      <div
        v-if="!userInfo.userName"
        class="df_btn"
        style="width:100px;margin:0 auto 10px;"
        @click="openAddDialog()"
      >+关联邮箱</div>
      <p v-if="!userInfo.userName" class="info_p">绑定邮箱，每天可获得更多进群次数</p>
      <div class="line">
        <span class="name">今日可用次数</span>
        <span class="value">{{userInfo.todayCanUseNumber || 0}}</span>
      </div>
      <div class="line">
        <span class="name">今日免费次数</span>
        <span class="value">{{userInfo.todayFreeNumber || 0}}</span>
      </div>
      <div class="line">
        <span class="name">分享奖励次数</span>
        <span class="value">{{userInfo.shareNumber || 0}}</span>
      </div>
      <div class="line">
        <span class="name">今日已使用次数</span>
        <span class="value">{{userInfo.todayHasUseNumber || 0}}</span>
      </div>
      <div class="btn" @click="refashData()">刷新</div>
      <div class="btn" @click="showShare=true">分享获得更多加群次数</div>
      <div class="btn" @click="showDialog2=true">登录其他账号</div>
    </div>
    <van-dialog v-model="showDialog" title :before-close="confirm" show-cancel-button>
      <h2 class="title_h2">关联邮箱</h2>
      <div class="textbox">
        <van-field v-model="emailAddress" label placeholder="请输入邮箱地址" />
      </div>
    </van-dialog>
    <van-dialog v-model="showDialog2" title :before-close="confirm2" show-cancel-button>
      <h2 class="title_h2">登录</h2>
      <div class="textbox">
        <van-field v-model="username" label placeholder="请输入用户名" />
      </div>
      <div class="textbox">
        <van-field v-model="password" type="password" label placeholder="请输入密码" />
      </div>
    </van-dialog>
    <van-share-sheet v-model="showShare" title="立即分享给好友" :options="options" @select="onSelect" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      keywords: "",
      showDialog2: false,
      username: "",
      password: "",
      showShare: false,
      options: [{ name: "复制链接", icon: "link" }],
      showDialog: false,
      email: "",
      emailAddress: "",
      searchText: "",
      info: {},
      userInfo: {},
      
    };
  },
  watch: {},
  computed: {},
  methods: {
    onSelect(opt) {
      //复制链接
      if (opt.icon === "link") {
        let host = window.location.host;
        let content = host + "/#/main/home?userUuid=" + this.userInfo.userUuid;
        this.copyOrderId(content);
        this.showShare = false;
      }
    },
    copyOrderId(orderId) {
      var input = document.createElement("input"); // 创建input对象
      input.value = orderId; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$toast.success("成功复制分享链接");
    },
    openAddDialog() {
      this.showDialog = true;
    },
    goHome() {
      this.$router.push({
        path: "/main/home",
      });
    },
    async confirm(action, done) {
      if (action === "cancel") {
        done();
        return false;
      }

      if (!this.emailAddress) {
        this.$toast("请输入邮箱");
        done(false);
        return false;
      }
      const myreg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
      if (!myreg.test(this.emailAddress)) {
        this.$toast("请输入正确的邮箱地址");
        done(false);
        return false;
      }
      const res = await this.request({
        url: "api/login/bind/send/user/email/?email=" + this.emailAddress,
        method: "get",
      });
      if (res.success) {
        this.$notify({ type: "success", message: res.result });
        done();
      } else {
        this.$toast(res.message);
        done(false);
      }
    },
    async confirm2(action, done) {
      if (action === "cancel") {
        done();
        return false;
      }
      if (!this.username || !this.password) {
        this.$toast("请输入用户名和密码");
        done(false);
        return false;
      }
      const res = await this.request({
        url: "api/login/doLogin",
        method: "post",
        data: {
          username: this.username,
          password: this.password,
        },
      });
      //todo 
      if (res.success) {
        this.$cookies.set('user_no_pass_cookie',res.result.token);
        this.$notify({ type: "success", message: res.message });
        this.initInfo();
        this.username = "";
        this.password = "";
        done();
      } else {
        this.$toast(res.message);
        done(false);
      }
    },
    async refashData() {
      //刷新
      const res = await this.request({
        url: "/api/login/bind/send/user/getInfo",
        method: "get",
      });
      this.userInfo = res.result;
      this.$notify({ type: "success", message: "刷新成功" });
    },
    async initInfo() {
      //获取用户信息
      const res = await this.request({
        url: "/api/login/bind/send/user/getInfo",
        method: "get",
      });
      this.userInfo = res.result;
    },
  },
  async mounted() {
    this.keywords = this.$route.query.keywords;
    this.info = JSON.parse(localStorage.getItem("info")) || {};
    this.initInfo();
    
  },
};
</script>
<style lang="less" socped>
.mine {
  width: 100%;
  min-height: calc(100% - 30px);
  padding: 0 10px 20px;
  box-sizing: border-box;
  .topBox {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .logoBox {
      flex: 1;
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        color: #fff;
        margin-left: 5px;
      }
    }
    .iconBox {
      display: flex;
      align-items: center;
      .df_btn{
        width: 66.23px;
        height: 33.11px;
        line-height: 33.11px;
        background: #284E74;
        color:#eee;
      }
    }
  }
  .content {
    width: 250px;
    margin: 0 auto;
    .email {
      font-size: 16px;
      color: #fff;
      text-align: center;
      margin-bottom: 30px;
    }
    .line {
      display: flex;
      align-items: center;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #fff;
      span{
        font-size: 15.4546px;
        color: #eee;
      }
      .name {
        flex: 1;
      }
    }
    .btn {
      height: 45px;
      line-height: 45px;
      text-align: center;
      color: #fff;
      background: #284E74;
      border-radius: 8px;
      margin-top: 20px;
    }
  }
  .title_h2 {
    line-height: 60px;
    text-align: center;
    font-size: 16px;
  }
  .textbox {
    border: 1px solid #ddd;
    margin: 0 20px 10px;
  }
  .info_p {
    color: #b5b5b5;
    font-size: 15px;
    margin: 10px 0;
    text-align: center;
  }
}
</style>
