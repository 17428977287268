<template>
  <div class="home">
    <div class="topBox">
      <div class="logoBox">
        <img :src="info.logoImg" style="width:37.53px" alt />
        <span style="font-size:23.184px">{{info.webLogoText}}</span>
      </div>
      <div class="iconBox">
        <img
          src="../../assets/images/kf.png"
          alt
          style="width:24px;margin-right:20px;"
          @click="goKf()"
        />
        <van-icon
          name="plus"
          size="24px"
          color="#fff"
          style="margin-right:20px;"
          @click="openAddDialog()"
        />
        <van-icon name="manager" size="24px" color="#fff" @click="goMine()" />
      </div>
    </div>
    <div class="swipeBox">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" height="80">
        <van-swipe-item v-for="item in imgList" :key="item.rotationId">
          <img :src="item.rotationImg" alt style="width:100%; height:80px" @click="goUrl(item.jumpSrc)" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="tabBox">
      <div :class="['tab',type==='RECOMMEND'?'on':'']" @click="changeType('RECOMMEND')">
        <span>推荐</span>
      </div>
      <div :class="['tab',type==='GROUP'?'on':'']" @click="changeType('GROUP')">
        <span>群组</span>
      </div>
      <div :class="['tab',type==='TIME'?'on':'']" @click="changeType('TIME')">
        <span>最新</span>
      </div>
    </div>
    <van-search
      v-model="key"
      shape="round"
      style="padding:0;height:37.52px"
      background="#000"
      label
      show-action
      placeholder="请输入搜索关键字"
      @focus="showPop=true"
    >
      <template #action>
        <div @click="onSearch" style="color:#fff;">搜索</div>
      </template>
    </van-search>
    <div v-show="showPop" class="popSearchBox">
      <div class="titleBox">
        <h3>大家都在搜</h3>
        <a href="javascript:;" @click="getWordsList()">换一换</a>
      </div>
      <div class="wordBox">
        <span v-for="item in wordsList" :key="item" @click="choiceWords(item)">{{item}}</span>
      </div>
    </div>
    <div class="content">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="box" v-for="(item,index) in dataList" :key="index">
          <img :src="item.headImgUrl" alt />
          <div class="infoBox">
            <div class="name">{{item.title}}</div>
            <div class="info">
              <span>{{item.memberCount}} members</span>
              |
              <em>{{item.updateTime}}</em>
            </div>
            <div class="says">{{item.description}}</div>
          </div>
          <div class="btn"><a :href="'https://gaga.ee/h5/'+item.id+'.html'">查看</a></div>
        </div>
      </van-list>
    </div>
    <van-dialog v-model="showDialog" title @confirm="confirm" show-cancel-button>
      <h2 class="title_h2">添加群组</h2>
      <div class="textbox">
        <van-field v-model="qunAddress" label placeholder="请输入群组地址" />
      </div>
    </van-dialog>
    <!-- <van-popup v-model="showPop" position="top">
      <div class="popSearchBox">
        <van-search
          v-model="key"
          shape="round"
          style="padding:0;"
          background="#000"
          show-action
          label
          placeholder="请输入搜索关键字"
        >
          <template #action>
            <div @click="onSearch" style="color:#fff;">搜索</div>
          </template>
        </van-search>
        <div class="titleBox">
          <h3>大家都在搜</h3>
          <a href="javascript:;" @click="getWordsList()">换一换</a>
        </div>
        <div class="wordBox">
          <span v-for="item in wordsList" :key="item" @click="choiceWords(item)">{{item}}</span>
        </div>
      </div>
    </van-popup>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPop: false,
      wordsList: [],
      loading: false,
      finished: false,
      showDialog: false,
      searchText: "",
      qunAddress: "",
      imgList: [],
      info: {},
      dataList: [],
      total: 0,
      pageNo: 0,
      pageSize: 10,
      key: "",
      type: "RECOMMEND", //填写（RECOMMEND）,为群组列表时候：填写（GROUP）为最新列表时，填写（TIME）
    };
  },
  methods: {
    goUrl(url){
      window.open(url, "_blank");
    },
    choiceWords(item) {
      this.showPop = false;
      this.key = item;
      this.onSearch();
    },
    goDetails(id) {
      //跳转详情
      this.$router.push({
        path: "/main/details",
        query: {
          id,
        },
      });
    },
    async onLoad() {
      console.log(23)
      this.pageNo++;
      // this.loading = true;
      const res = await this.request({
        url: "/api/local-api/bug_keyword/list",
        method: "get",
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          type: this.type,
          key: this.key,
        },
      });
      this.loading = false;
      if (!res.success) {
        this.finished = true;
        return false;
      }
      this.dataList = this.dataList.concat(res.data.list);
      this.total = res.data.total;
      if (this.pageNo * this.pageSize >= this.total) {
        this.finished = true;
      }
    },
    onSearch() {
      this.showPop = false;
      this.dataList = [];
      this.pageNo = 0;
      this.onLoad();
    },
    changeType(type) {
      this.showPop = false;
      if (this.type === type) return;
      this.type = type;
      this.dataList = [];
      this.pageNo = 0;
      this.finished = false;
      this.onLoad();
    },
    goKf() {
      window.location.href = this.info.customerService;
    },
    goMine() {
      this.$router.push({
        path: "/main/mine?keywords=" + this.key,
      });
    },
    openAddDialog() {
      this.showDialog = true;
    },
    async confirm() {
      const res = await this.request({
        url: "/api/local-api/bug_keyword/subLink",
        method: "get",
        params: {
          url: this.qunAddress,
        },
      });
      if (res.success) {
        this.$toast("添加成功");
      } else {
        this.$toast("添加失败");
      }
    },
    async getWordsList() {
      //获取热搜关键词
      const res2 = await this.request({
        url: "/api/local-api/bug_keyword/randKeyword",
        method: "get",
      });
      this.wordsList = res2.data.key;
    },
  },
  destroyed(){
    window.removeEventListener('scroll',()=>{
      this.timer = null;
    },true)
  },
  async mounted() {

    this.timer = window.addEventListener('scroll', ()=>{
      this.showPop = false;
    })

    this.info = JSON.parse(localStorage.getItem("info")) || {};

    //判断是否有分享
    let url = window.location.href;
    let params = url.split("?")[1];
    if (params) {
      let userUuid = params.split("=")[1];
      this.request({
        url: "/api/login/share/check",
        method: "get",
        params: {
          userUuid,
        },
      });
    }

    //获取轮播接口
    const res = await this.request({
      url: "/api/local-api/rotation/page",
      method: "get",
    });

    this.imgList = res.data.list;

    this.getWordsList();
  },
};
</script>
<style lang="less" socped>
.home {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  .topBox {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .logoBox {
      flex: 1;
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        color: #fff;
        margin-left: 5px;
      }
    }
    .iconBox {
      display: flex;
      align-items: center;
      .df_btn{
        width: 66.23px;
        height: 33.11px;
        line-height: 33.11px;
        background: #284E74;
        color:#eee;
      }
    }
  }
  .swipeBox {
    height: 80px;
    background: #fff;
  }
  .tabBox {
    display: flex;
    align-items: center;
    padding: 13px 0;
    .tab {
      flex: 1;
      color: #a19d9d;
      text-align: center;
      span {
        border-bottom: 4px solid #000;
        display: inline-block;
        padding: 0 5px 5px;
        font-size: 15.4546px;
      }
    }
    .on {
      color: #fff;
      span {
        border-bottom: 4px solid #828282;
      }
    }
  }
  .content {
    .box {
      display: flex;
      align-items: center;
      margin-top: 10px;
      img {
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 8px;
        margin: 0 5px 0 5px;
      }
      .infoBox {
        color: #fff;
        flex: 1;
        width: 0;
        .name {
          font-size: 15.4546px;
        }
        .info {
          display: flex;
          margin-top: 5px;
          font-size: 13.248px;
          align-items: center;
          span {
            margin-right: 5px;
            font-size: 13.248px;
            color:#999;
          }
          em {
            margin-left: 5px;
            color:#999;
          }
        }
        .says {
          margin-top: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12.6974px;
          color:#999;
        }
      }
      .btn {
        width: 55px;
        height: 26px;
        line-height: 26px;
        background: #284E74;
        color: #fff;
        border-radius: 8px;
        text-align: center;
      }
    }
  }
  .title_h2 {
    line-height: 60px;
    text-align: center;
    font-size: 16px;
    color: #333;
  }
  .textbox {
    border: 1px solid #ddd;
    margin: 0 20px 10px;
  }
  .popSearchBox {
    background: #000;
    padding: 20px 0;
    .titleBox {
      display: flex;
      align-items: center;
      h3 {
        font-size: 16px;
        color: #fff;
        flex: 1;
      }
      a {
        padding: 5px 10px;
        color: #1d68b3;
        font-size: 16px;
      }
    }
    .wordBox {
      margin-top: 5px;
      margin-left: -10px;
      span {
        display: inline-block;
        height: 26px;
        line-height: 26px;
        border-radius: 40px;
        padding: 0 10px;
        background: #3e3e3e;
        color: #fff;
        text-align: center;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }
}
</style>
