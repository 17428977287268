<template>
  <div class="setPwd">
    <div class="topBox">
      <div class="logoBox">
        <img :src="info.logoImg" style="width:37.53px" alt />
        <span style="font-size:23.184px">{{info.webLogoText}}</span>
      </div>
      <div class="iconBox">
        <div class="df_btn" @click="goHome()">首页</div>
      </div>
    </div>
    <div class="content">
      <img src="../../assets/images/logo.png" style="width:80px;display:block;margin:20px auto;" />
      <p class="email">设置密码</p>
      <div class="textbox">
        <van-field v-model="pwd" type="password" label placeholder="请输入密码" />
      </div>
      <div class="textbox">
        <van-field v-model="rePwd" type="password" label placeholder="请再次输入密码" />
      </div>
      <div class="btn" @click="confirmRegeist()">确认注册</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pwd: "",
      rePwd: "",
      info: {},
      userInfo: {},
      token: "c927780cc4d75f5f0beaac50bc792541",
    };
  },
  watch: {},
  computed: {},
  methods: {
    goHome() {
      this.$router.push({
        path: "/main/home",
      });
    },
    async confirmRegeist() {
      if (!this.pwd || !this.rePwd) {
        this.$toast("请输入密码");
        return false;
      }
      if (this.pwd !== this.rePwd) {
        this.$toast("两次输入密码不一致");
        return false;
      }
      //确认注册
      const res = await this.request({
        url: "/api/login/bind/user/email",
        method: "post",
        data: {
          email: this.userInfo.userName,
          nickName: this.userInfo.nickName,
          pass: this.pwd,
          userUuid: this.userInfo.userUuid,
        },
      });
      if (res.success) {
        this.$notify({ type: "success", message: res.message });
        setTimeout(() => {
          this.$router.push(
            {
              path: "/main/mine",
            }
          );
        },800);
      } else {
        this.$toast(res.message);
      }
    },
  },
  async mounted() {
    let url = window.location.href;
    let params = url.split("?")[1];
    this.toekn = params.split("=")[1];

    this.info = JSON.parse(localStorage.getItem("info")) || {};
    //获取用户信息
    const res = await this.request({
      url: "/api/login/bind/send/user/getInfo",
      method: "get",
    });
    this.userInfo = res.result;
  },
};
</script>
<style lang="less" socped>
.setPwd {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  .topBox {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .logoBox {
      flex: 1;
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        color: #fff;
        margin-left: 5px;
      }
    }
    .iconBox {
      display: flex;
      align-items: center;
      .df_btn{
        width: 66.23px;
        height: 33.11px;
        line-height: 33.11px;
        background: #284E74;
        color:#eee;
      }
    }
  }
  .content {
    width: 250px;
    margin: 0 auto;
    .email {
      font-size: 16px;
      color: #fff;
      text-align: center;
      margin-bottom: 30px;
    }
    .line {
      display: flex;
      align-items: center;
      color: #fff;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #fff;
      .name {
        flex: 1;
      }
    }
    .btn {
      height: 45px;
      line-height: 45px;
      text-align: center;
      color: #fff;
      background: #284E74;
      border-radius: 8px;
      margin-top: 20px;
    }
  }
  .title_h2 {
    line-height: 60px;
    text-align: center;
    font-size: 16px;
  }
  .textbox {
    margin: 0 0 20px;
  }
}
</style>
